<template>
  <i class="fi fi-rr-interrogation bg-bespin fg-lightYellow p-panel-header-icon text-xl mr-1" @click="toogleHelpDiag" v-tooltip="`<span class='fg-lightYellow'>Hilfe</span><hr class='mt-1 mb-1' /> ${text}`"></i>
  <i :class="`${icon} bg-bespin fg-lightYellow p-panel-header-icon text-xl`"></i>

  <Dialog v-model:visible="helpDiag" modal header="Hilfe" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <span v-html="text" class="mb-5 line-height-3 text-justify"></span>
    </Dialog>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "moduleDialogIconHelp",
  setup() {},
  components: {
  },
  emits: ['toogleAlarmOverlay'],
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      helpDiag: false,
    }
  },
  watch: {

  },
  computed: {
    fgColor1: function () {
      if (this.fgColor) {
        return `fg-${this.fgColor}`;
      }
      return '';
    },
    badge1: function () {
      if (this.badge) {
        return this.badge.toString();
      }
      return null;
    },
  },
  mounted() {
  },
  methods: {
    toogleHelpDiag() {
      this.helpDiag = true;
    },
  },
});
</script>

<style lang="scss" scoped>
</style>